<template>
    <div :key="slug" :class="{'tabs__panel--active': active}" :data-slug="slug" class="tabs__panel">
        <slot :active="active" />
    </div>
</template>

<script>
    export default {
        name: 'TabbedContentTab',

        props: {
            title: {
                type: String,
                required: true,
            },

            slug: {
                type: String,
                required: true,
            },

            order: {
                type: Number,
                default: 1,
            },

            selected: {
                type: Boolean,
                default: false,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            saveAndSwitch: {
                type: String,
                default: null,
            },

            label: {
                type: String,
                default: null,
            },

            icon: {
                type: String,
                default: null,
            },
        },

        data() {
            return {
                active: this.selected,
            };
        },
    };
</script>
